<template>
<div class="shop-cont">
    <el-row class="shop-tit" :gutter="12">
        <el-col :span="18">
            <div class="tit-wrp">店长推荐</div>
        </el-col>
        <el-col :span="6">
            <div class="tit-wrp"> 店铺信息</div>
        </el-col>
    </el-row>
    <el-row>
        <el-col :span="18">
            <div class="home">
                <el-row class="products">
                    <el-col :span="6" v-for="(item,index) in products" :key="index">
                        <div class="prods" @click="jump(item.Product_ID)">
                            <div class="podtImg">
                                <img class="" :src="'https://www.visasiv.cn' +item.Product_ImageUrl" alt="" srcset="">
                            </div>
                            <p>{{item.Product_Name}}</p>
                            <div>
                                <span>￥<strong> {{item.Product_SellPrice}}</strong> 元</span>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </el-col>
        <el-col :span="6">
            <div class="shopInfo">
                <h4>{{merchart.Merchant_Name || '平台自营'}}</h4>
                <p>营业时间：{{merchart.Merchant_BussinessHours || '9：00 - 5：00'}}</p>
                <p>联系方式：{{merchart.Merchant_Phone || '18006888696'}}</p>
                <p>地址：{{merchart.Merchant_Address || '浙江省温州市鹿城区大南街道锦春大厦志愿者中心'}}</p>
            </div>
        </el-col>
    </el-row>
</div>
</template>

<script>
let app = null
import productImg from "@/assets/product.jpg"
import bn1 from "@/assets/bn1.png"
import bn2 from "@/assets/bn2.png"
import bn3 from "@/assets/bn3.png"

export default {
    data() {
        return {
            domin: "",
            mid:0,
            imageslist: [bn1, bn2, bn3],
            merchart:JSON.parse(localStorage.getItem('shopInfo')),
            products: []
        }
    },
    created() {
        app = this.$.appContext.app
        this.domin = app.domin
        this.mid = this.$route.params.id
        console.log("mid:",this.mid)
        this.getDetail()
    },
    methods: {
        jump(id) {
            this.$router.push({
                path: '/details/' + id
            })
        },
        getDetail() {
            app.wpost({
                url: "/Ajax/WordWelfare/getproductlist",
                data: {
                    pagesize:999
                },
                succ: (data) => {
                    console.log("res", data)
                    this.products = data.list.filter(item=>{
                        if(item.Merchant_ID == this.mid )
                        return item
                    })

                },
            });
        }
    }
}
</script>

<style scoped>
.shop-tit .tit-wrp{
    background-color: #ebebeb;
    line-height: 35px;
    text-align: center;
    margin: 0 1px;
}

.shop-cont {
    padding: 20px;
}

.shopInfo span {
    color: #1f96f8;
    border: 1px solid #1f96f8;
    border-radius: 2px;
    padding: 2px 3px;

}

.shopInfo span:hover {
    background-color: #1f96f8;
    color: #fff;
}

.shopInfo {
    box-sizing: border-box;
    padding: 10px;
    width: 320px;
    font-size: 12px;
    margin: 20px auto;
    border: 1px solid #dedede
}

.shopInfo img {
    width: 100%;
    border: 1px solid #dedede;
}

.products {
    border: 1px solid #dedede;
    box-sizing: border-box;
    padding: 30px 10px;
    margin: 20px 0;
    margin-right: 20px;
}

.prods:hover {
    cursor: pointer;
    background-color: #fafafa;
}

.prods img {
    width: 90%;
    padding: 10px;
}

.prods strong {
    font-size: 16px;
    color: firebrick;
}

.podtImg {
    width: 98%;
    height: 170px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    background-color: #fafafa;
    box-shadow: 0 0 3px #fafafa;
}
</style>
